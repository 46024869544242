/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@rails/ujs").start();
import "html5shiv";
import "bxslider/dist/jquery.bxslider";
import "../src/candidate-application";
import "../src/external_js";

document.addEventListener("DOMContentLoaded", function () {
  bindContactForm("new_contact");

  // makes sure the `.values` elements are 100% of the window
  // TODO: this can be done with just CSS, also the `.values` elements
  // will be gone after the redesign so this can be removed eventually
  let values = document.querySelector(".values");
  if (values) {
    function setHeight() {
      const windowHeight = window.innerHeight;
      values.style.minHeight = windowHeight + "px";
    }
    setHeight();
    $(window).resize(setHeight);
  }

  //mobile menu
  const menu = document.getElementById("menu");

  $("#mobile-menu").click(function () {
    event.preventDefault();

    menu.classList.toggle("active");
    if (menu.classList.contains("active")) {
      this.title = "Close Menu";
      menu.focus();
    } else {
      this.title = "Open Menu";
    }
  });

  document.querySelectorAll(".service-card").forEach((element) => {
    element.addEventListener("click", (event) => {
      event.preventDefault();
      const service_details_card = document.querySelector(`#service-details-card-${element.id} .scroll-anchor`);

      service_details_card.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    })
  })

  document.querySelectorAll(".scroll-to-contact-us").forEach((element) => {
    element.addEventListener("click", (event) => {
      event.preventDefault();
      const formSection = document.getElementById("scroll-anchor-contact-us");
      formSection.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    })
  })

  document.querySelectorAll(".see-more-target").forEach((el) => {
    el.addEventListener("click", (event) => {
      event.preventDefault();
      const element = event.target.closest(".service-details-card");

      if (element.classList.contains("see-more-expanded")) {
        element.classList.remove("see-more-expanded");
        event.currentTarget.innerHTML =
          'See More <i class="fa fa-angle-down"></i>';
      } else {
        element.classList.add("see-more-expanded");
        event.currentTarget.innerHTML =
          'See Less <i class="fa fa-angle-up"></i>';
      }
    });
  });

  document.querySelectorAll("a.scroll").forEach((el) => {
    el.addEventListener("click", (e) => {
      if (!e.target.hash) return;

      const scrollTo = document.getElementById(e.target.hash.replace("#", ""));
      if (!scrollTo) return;

      e.preventDefault();

      document.querySelector("html").scrollTo({
        left: 0,
        top: scrollTo.getBoundingClientRect().top,
        behavior: "smooth",
      });
    });
  });

  //slider testimonials
  // TODO: remove after the redesign is complete, we won't use a slider anymore
  $(".slider").bxSlider({
    mode: "fade",
    auto: true,
    speed: 500,
    pause: 20000,
    autoHover: true,
    pager: false,
    autoHover: true,
    stopAutoOnClick: true,
    nextText: '<i class="fa fa-angle-right"></i>',
    prevText: '<i class="fa fa-angle-left"></i>',
    nextSelector: ".next-arrow",
    prevSelector: ".prev-arrow",
    onSliderLoad: function () {
      $(".prev-arrow a").attr("aria-label", "Previous slide");
      $(".next-arrow a").attr("aria-label", "Next slide");
    },
  });

  // Change the color of the navbar when scrolling
  function checkFixedHeader() {
    const scroll = window.scrollY;
    const header = document.querySelector("header");
    if (scroll >= 5) {
      header.classList.add("fixed");
    } else {
      header.classList.remove("fixed");
    }
  }

  $(window).scroll(checkFixedHeader);
  checkFixedHeader();

  // TODO: verify if we still want to fetch repositories from github or hardcode
  // specific repos
  function getGithubData() {
    let repos = document.getElementById("repositories");
    if (!repos) return; // don't do the request if there's no div

    try {
      // Main organization information
      $.get("https://api.github.com/orgs/fastruby", function (data) {
        repos.innerHTML = "Repositories " + data.public_repos;
      });
    } catch (err) {
      console.error(err);
    }
  }

  // TODO: verify this is still needed, there seems to be no `people` element anymore
  function getGithubMembers() {
    let people = document.getElementById("people");
    if (!people) return; // don't do the request if there's no div

    try {
      // Main organization information
      $.get("https://api.github.com/orgs/fastruby/members", function (data) {
        people.innerHTML = "People " + data.length;
      });
    } catch (err) {
      console.error(err);
    }
  }

  // TODO: verify this is still needed, there seems to be no `shots` element anymore
  function getDribbleShots() {
    let shots = document.querySelector(".shots");
    if (!shots) return; // don't do the request if there's no div

    // Dribbble section
    const accessToken =
      "bae6d4e349f1dddd8ccdc3dd1898adaba21a9e1b8666d271ef1ea56e7ec2571f";

    $.ajax({
      url: "https://api.dribbble.com/v2/user/shots?access_token=" + accessToken,
      dataType: "json",
      type: "GET",
      success: function (data) {
        let html = [];

        $.each(data, function (i, shot) {
          html.push('<li class="shots--shot" aria-hidden="true">');
          html.push(
            '<a href="' +
              shot.html_url +
              '" target="_blank" aria-hidden="true">'
          );
          html.push(
            '<img src="' + shot.images.normal + '" aria-hidden="true">'
          );
          html.push("</a></li>");
        });

        shots.innerHTML = html.join("");
      },
    });
  }

  getGithubMembers();
  getGithubData();
  // getGithubRepos();
  getDribbleShots();
});

// MAPS section
var map;
var marker;
global.initMap = function () {
  var myLatlng = { lat: 40.046991, lng: -75.1949719 };
  var center = { lat: 40.046991, lng: -75.1971445 };
  var tabletCenter = { lat: 40.046991, lng: -75.1970755 };
  var mobileCenter = { lat: 40.0480719, lng: -75.1949719 };
  map = new google.maps.Map(document.getElementById("map"), {
    zoom: 17,
    disableDefaultUI: true,
    styles: [
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#ebe3cd",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#523735",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#f5f1e6",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#c9b2a6",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#dcd2be",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#ae9e90",
          },
        ],
      },
      {
        featureType: "landscape.man_made",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ffcc00",
          },
        ],
      },
      {
        featureType: "landscape.natural",
        elementType: "geometry",
        stylers: [
          {
            color: "#dfd2ae",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            color: "#dfd2ae",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#93817c",
          },
        ],
      },
      {
        featureType: "poi.business",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#1bb076",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#447530",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f1e6",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
          {
            color: "#fdfcf8",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#f8c967",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#f70a7c",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#e9bc62",
          },
        ],
      },
      {
        featureType: "road.highway.controlled_access",
        elementType: "geometry",
        stylers: [
          {
            color: "#e98d58",
          },
        ],
      },
      {
        featureType: "road.highway.controlled_access",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#db8555",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#806b63",
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
          {
            color: "#dfd2ae",
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#8f7d77",
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#ebe3cd",
          },
        ],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [
          {
            color: "#dfd2ae",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#d3f2f1",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#92998d",
          },
        ],
      },
    ],
  });
  map.setOptions({
    draggable: false,
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
  });
  var pin = {
    path: "M25.5,0C11.4,0,0,11.5,0,25.7c0,17.6,22.8,43.4,23.8,44.5c0.9,1,2.5,1,3.4,0c1-1.1,23.8-26.9,23.8-44.5 C51,11.5,39.6,0,25.5,0z M26,39c-7.2,0-13-5.8-13-13s5.8-13,13-13s13,5.8,13,13S33.2,39,26,39z",
    fillColor: "black",
    fillOpacity: 1,
    scale: 1,
    size: new google.maps.Size(51, 71),
    origin: new google.maps.Point(0, 0),
    // The anchor for this image is the base of the flagpole at (0, 32).
    anchor: new google.maps.Point(15, 60),
  };
  marker = new google.maps.Marker({
    position: myLatlng,
    icon: pin,
    map: map,
  });

  const setCenter = () => {
    if (window.innerWidth <= 600) {
      map.setCenter(mobileCenter);
    } else if (window.innerWidth <= 768) {
      map.setCenter(tabletCenter);
    } else {
      map.setCenter(center);
    }
  };

  google.maps.event.addDomListener(window, "load", function () {
    setCenter();
  });
  google.maps.event.addDomListener(window, "resize", function () {
    setCenter();
  });
};

// insert an error div after the given input
function addErrorField(inputId, message) {
  const input = document.getElementById(inputId);
  const errorDiv = document.createElement("div");
  errorDiv.innerText = message;
  errorDiv.classList.add("input-error");
  input.insertAdjacentElement("afterend", errorDiv);
  input.parentElement.classList.add("has-error");
}

function bindContactForm(formId) {
  const contactForm = document.getElementById(formId);
  if (!contactForm) return;

  const nameInput = document.querySelector('[name="contact[name]"');
  const emailInput = document.querySelector('[name="contact[email]"');
  const requestTypeInput = document.querySelector(
    '[name="contact[request_type]"'
  );
  const messageInputContainer = document.getElementById("message-input");

  contactForm.addEventListener("ajax:success", function (event) {
    if (event.detail[0].redirect_to) {
      window.location = event.detail[0].redirect_to;
    } else {
      $("#new_contact").slideUp(200, function () {
        const msg =
          '<div id="contact-success-wrapper" class="contact-img"><img src="/assets/contact.gif" /></div><h2 class="form-success">Thank you! <br>We will get back to you soon ;-) </h2>';
        document.getElementById("new_contact").innerHTML = msg;
        $("#new_contact").slideDown(200);
      });
    }
  });

  contactForm.addEventListener("ajax:error", function (e) {
    const [data, status, xhr] = e.detail;

    // clear error state
    contactForm.querySelectorAll(".input-error").forEach((e) => e.remove());
    contactForm
      .querySelectorAll(".has-error")
      .forEach((e) => e.classList.remove("has-error"));

    // process new errors
    for (let error in data) {
      switch (error) {
        case "email":
          addErrorField("contact_email", `Email ${data[error]}`);
          break;
        case "name":
          addErrorField("contact_name", `Your name ${data[error]}`);
          break;
        case "request_type":
          addErrorField("contact_request_type", `Request type ${data[error]}`);
          break;
        case "message":
          addErrorField("contact_message", `Message ${data[error]}`);
          break;
      }
    }
  });

  function toggleMessageInputContainer() {
    if (
      emailInput.validationMessage === "" &&
      nameInput.value.trim() !== "" &&
      emailInput.value.trim() !== "" &&
      requestTypeInput.value.trim() !== ""
    ) {
      messageInputContainer.classList.remove("hidden");
    } else {
      messageInputContainer.classList.add("hidden");
    }
  }

  function validateEmailInput() {
    emailInput.parentElement.classList.remove("has-error");
    emailInput.parentElement.querySelector(".input-error")?.remove();
    if (emailInput.validationMessage !== "") {
      addErrorField("contact_email", `Email ${emailInput.validationMessage}`);
    }
    toggleMessageInputContainer();
  }

  if (emailInput) {
    emailInput.addEventListener("blur", validateEmailInput);
  }

  if (nameInput) {
    nameInput.addEventListener("input", toggleMessageInputContainer);
  }

  if (requestTypeInput) {
    requestTypeInput.addEventListener("change", toggleMessageInputContainer);
  }
}
