import Rails from "@rails/ujs";

document.addEventListener("DOMContentLoaded", () => {
  const applicationWrapper = document.getElementById("application");
  if (!applicationWrapper) return;

  const form = applicationWrapper.querySelector("form");

  const sections = form.querySelectorAll("section");

  const validCountries = [
    ...form.querySelectorAll("#suggested_countries button"),
  ].map((button) => button.value);
  const validStates = [
    ...form.querySelectorAll("#suggested_states button"),
  ].map((button) => button.value);

  form.addEventListener("ajax:success", () => {
    window.location = `${window.location.origin}${window.location.pathname}/submitted`;
  });

  form.addEventListener("ajax:error", () => {
    alert("Something went wrong, please try again in a few minutes.");
  });

  form.querySelectorAll("input, textarea").forEach((el) => {
    el.addEventListener("focus", () => {
      const wrapper = el.closest("section");
      if (wrapper) form.dataset.currentStep = wrapper.id;
    });
  });

  // Safari-like browsers don't trigger the submit event when pressing Enter in fields with a datalist
  if (navigator.vendor.match(/Apple/)) {
    form.querySelectorAll("input[list]").forEach((el) => {
      el.addEventListener("keyup", (ev) => {
        if (ev.key === "Enter") {
          form.dispatchEvent(new SubmitEvent("submit"));
        }
      });
    });
  }

  const currentStep = () => {
    return document.getElementById(form.dataset.currentStep || "first_name");
  };

  const focusStepField = (step) => {
    const toFocus = step.querySelector("input, textarea");
    if (toFocus) {
      toFocus.focus();
    }
  }

  const focusCurrentStepField = (entries) => {
    const entry = entries[0];
    if (!entry.isIntersecting) {
      return;
    }
    focusStepField(entry.target);
  }

  const focusCurrentStepFieldObserver = new IntersectionObserver(focusCurrentStepField, { threshold: 1 })

  sections.forEach(el => focusCurrentStepFieldObserver.observe(el));

  const hasNextStep = () => {
    return !!currentStep().nextElementSibling;
  };

  const validateAllSteps = () => {
    applicationWrapper.querySelectorAll("section").forEach((el) => {
      const stepInputs = el.querySelectorAll("input, textarea");

      stepInputs.forEach((input) => {
        input.dataset.dirty = true;
        validateElement(input);
      });
    });

    updateErrorsBox();
  };

  form.addEventListener("submit", (event) => {
    const inReview = document.body.classList.contains("collapse");

    if (inReview) {
      validateAllSteps();
    }

    const hasErrors =
      applicationWrapper.querySelectorAll(".has-error").length !== 0;

    if (!inReview || hasErrors) {
      Rails.stopEverything(event);

      validateCurrentStep();

      if (!inReview && currentStepValid() && hasNextStep()) {
        scrollToNextStep();
      }
    }
  });

  const isPresent = (element) => {
    return element.value.trim() !== "";
  };

  const validateElement = (element) => {
    if (!element.dataset.dirty) return;

    const fieldClassList = element.closest("div").classList;
    let isValid = true;

    if (element.dataset.required) {
      isValid = isValid && isPresent(element);
    }

    if (element.dataset.format) {
      const regexp = new RegExp(element.dataset.format);
      isValid = isValid && regexp.test(element.value);
    }

    if (element.dataset.mustBeCountry) {
      const userInput = element.value.trim();
      isValid = isValid && validCountries.includes(userInput);
    }

    if (element.dataset.mustBeState && element.dataset.required) {
      const userInput = element.value.trim();
      isValid = isValid && validStates.includes(userInput);
    }

    if (element.dataset.mustBeFuture) {
      const startDate = new Date(element.value.trim());
      const today = new Date();
      const isInTheFuture = startDate > today;
      isValid = isValid && isInTheFuture;
    }

    if (isValid) {
      fieldClassList.remove("has-error");
    } else {
      fieldClassList.add("has-error");
    }
  };

  const validateCurrentStep = () => {
    const currentStepInputs = currentStep().querySelectorAll("input, textarea");

    currentStepInputs.forEach((input) => {
      input.dataset.dirty = true;
      validateElement(input);
    });
  };

  const currentStepValid = () => {
    return !currentStep().querySelector(".has-error");
  };

  const scrollToNextStep = () => {
    const nextStep = currentStep().nextElementSibling;
    scrollToById(nextStep.id);
  };

  const scrollBack = () => {
    const previousStep = currentStep().previousElementSibling;
    scrollToById(previousStep.id);
  };

  const scrollToById = (id) => {
    form.dataset.currentStep = id;

    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
    });
  };

  window.addEventListener("resize", function() {
    scrollToById(currentStep().id)
  });

  document
    .querySelectorAll("[data-required], [data-format], .with-suggestions")
    .forEach((el) => {
      el.addEventListener("input", (event) => validateElement(event.target));
      el.addEventListener("blur", (event) => {
        event.target.dataset.dirty = true;
        validateElement(event.target);
      });
    });

  document.querySelectorAll("a.scroll-next").forEach((el) => {
    el.addEventListener("click", (event) => {
      event.preventDefault();

      form.dataset.currentStep = event.target.closest("section").id;

      scrollToNextStep();
    });
  });

  document.querySelectorAll("a.scroll-back").forEach((el) => {
    el.addEventListener("click", (event) => {
      event.preventDefault();

      form.dataset.currentStep = event.target.closest("section").id;

      scrollBack();
    });
  });

  const errorsBox = document.getElementById("form-errors");
  const errorsList = errorsBox.querySelector("ul");
  const updateErrorsBox = () => {
    errorsList.innerHTML = "";
    const fieldsWithErrors = form.querySelectorAll(".field.has-error label");
    if (fieldsWithErrors.length) {
      fieldsWithErrors.forEach((label) => {
        const li = document.createElement("li");
        const button = document.createElement("button");
        button.innerText = label.innerText.replace("*", "");
        button.addEventListener("click", (e) => {
          e.preventDefault();

          // scroll to element, considering navbar height
          const header = document.querySelector("header");
          window.scrollTo({
            top: label.getBoundingClientRect().top - header.clientHeight - 40,
            left: 0,
            behavior: "smooth",
          });

          // wait for smooth scroll before setting focus on the field
          setTimeout(() => {
            label.focus();
          }, 600);
        });

        li.appendChild(button);
        errorsList.insertAdjacentElement("beforeend", li);
      });
      errorsBox.classList.remove("hidden");
      window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    } else {
      errorsBox.classList.add("hidden");
    }
  };

  document.getElementById("review").addEventListener("click", (e) => {
    e.preventDefault;

    document.body.classList.add("collapse");

    validateAllSteps();

    window.scrollTo({ top: 0 });
  });

  document
    .getElementById("back-to-application")
    .addEventListener("click", (e) => {
      e.preventDefault;

      document.body.classList.remove("collapse");

      window.scrollTo({ top: document.body.scrollHeight });
    });

  const candidateLocationInput = document.getElementById("candidate_location");
  const candidateStateInput = document.getElementById("candidate_state");
  const stateDiv = document.querySelector("#location > div.field.state");

  candidateLocationInput.addEventListener("input", () => {
    const text = candidateLocationInput.value.toLowerCase();

    if (text === "united states") {
      stateDiv.style.display = "block";
      candidateStateInput.dataset.required = true;
    } else {
      stateDiv.style.display = "none";
      delete candidateStateInput.dataset.required;
      candidateStateInput.value = "";
    }
  });

  const foundUsInput = document.getElementById("candidate_found_us");
  const foundUsOtherInput = document.getElementById("candidate_found_us_other");
  const foundUsOtherDiv = document.querySelector("#found_us > div.field.found_us_other");

  foundUsInput.addEventListener("input", () => {
    const text = foundUsInput.value.toLowerCase();

    if (text === "other") {
      foundUsOtherDiv.style.display = "block";
      foundUsOtherInput.dataset.required = true;
    } else {
      foundUsOtherDiv.style.display = "none";
      delete foundUsOtherInput.dataset.required;
      foundUsOtherInput.value = "";
    }
  });

  // create the custom widget for input with dropdown
  const bindSuggestions = (input) => {
    const wrapper = input.parentElement;
    const suggestionsWrapper = wrapper.querySelector(".suggestions");
    const allSuggestions = suggestionsWrapper.querySelectorAll("button");
    const validValues = [...allSuggestions].map((button) => button.value);

    // we have to handle show/hide with JS and timeouts because of Safari
    let suggestionsTimer;

    wrapper.addEventListener(
      "focus",
      () => {
        if (suggestionsTimer) clearTimeout(suggestionsTimer);
        suggestionsWrapper.classList.add("shown");
      },
      { capture: true }
    );

    wrapper.addEventListener(
      "blur",
      () => {
        suggestionsTimer = setTimeout(() => {
          suggestionsWrapper.classList.remove("shown");
        }, 300);
      },
      { capture: true }
    );

    // when clicking a suggestion, set it as the value for the field
    wrapper.addEventListener("click", (ev) => {
      const tgt = ev.target;
      const isSuggestion =
        tgt.tagName === "BUTTON" &&
        tgt.parentElement.classList.contains("suggestions");
      if (isSuggestion) {
        ev.preventDefault();
        input.value = tgt.value;

        // dispatch event to trigger callbacks
        input.dispatchEvent(new InputEvent("input"));

        // hide all suggestions after picking a suggestion
        allSuggestions.forEach((button) => button.classList.remove("visible"));

        validateElement(input);
        input.focus();
      }
    });

    // handle moving through suggestions using the keyboard
    wrapper.addEventListener("keydown", (ev) => {
      // let Enter work normally
      if (ev.key === "Enter") return;

      const tgt = ev.target;
      const isInput = tgt.tagName === "INPUT";
      const isSuggestion =
        tgt.tagName === "BUTTON" &&
        tgt.parentElement.classList.contains("suggestions");

      // if pressing a key that's not an arrow in a suggestion,
      // focus the input instead, that way we can continue typing in the input
      if (!["ArrowUp", "ArrowDown"].includes(ev.key)) {
        if (isSuggestion) {
          input.focus();
        }
        return;
      }

      // prevent default so the arrows don't make the suggestions scroll
      ev.preventDefault();

      const visibleSuggestions = [
        ...wrapper.querySelectorAll(".suggestions button.visible"),
      ];

      // if pressing down when focusing the input, focus the first suggestion
      if (isInput && ev.key === "ArrowDown") {
        visibleSuggestions[0]?.focus();
      }

      if (isSuggestion) {
        const currentIndex = visibleSuggestions.indexOf(tgt);
        let newIndex = currentIndex;

        if (ev.key === "ArrowUp") {
          if (currentIndex === 0) {
            // if pressing up when focusing the first suggestion, focus the input
            input.focus();
            return;
          } else {
            // if not the first suggestion, focus the previous one
            newIndex--;
          }
        }

        // if pressing down when focusing a suggestion that is not the last one, focus the next
        if (
          ev.key === "ArrowDown" &&
          newIndex < visibleSuggestions.length - 1
        ) {
          newIndex++;
        }

        visibleSuggestions[newIndex]?.focus();
      }
    });

    // filter suggestions based on the input's value
    const filterSuggestions = () => {
      const text = input.value.toLowerCase();

      // hide suggestions that don't match the input
      allSuggestions.forEach((button) => {
        // only show suggestions if input is more than 1 char long
        if (button.value.toLowerCase().includes(text)) {
          button.classList.add("visible");
        } else {
          button.classList.remove("visible");
        }
      });

      let visibleSuggestions = wrapper.querySelectorAll(
        ".suggestions button.visible"
      );

      // if there's only one suggestion, hide it if it's the current text
      if (visibleSuggestions.length === 1) {
        if (visibleSuggestions[0].value.toLowerCase() === text) {
          visibleSuggestions[0].classList.remove("visible");
        }
      }

      visibleSuggestions = wrapper.querySelectorAll(
        ".suggestions button.visible"
      );

      // we have to set this class to remove padding, a current CSS limitation
      // can be replaced in the future when the `:has` selector is available
      if (visibleSuggestions.length === 0) {
        suggestionsWrapper.classList.add("empty");
      } else {
        suggestionsWrapper.classList.remove("empty");
      }
    };

    input.addEventListener("focus", () => filterSuggestions());
    input.addEventListener("input", () => filterSuggestions());

    // when blurring the field, if there's one suggestion and it matches
    // the current value, set it to fix case differencies
    input.addEventListener("blur", () => {
      input.dataset.dirty = true;

      const currentInputValue = input.value.toLowerCase();
      const filteredSuggestions = validValues.filter(
        (value) => value.toLowerCase() == currentInputValue
      );

      if (filteredSuggestions.length === 1) {
        input.value = filteredSuggestions[0];
      }

      validateElement(input);
    });
  };

  form
    .querySelectorAll(".with-suggestions")
    .forEach((input) => bindSuggestions(input));
});
