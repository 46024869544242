document.addEventListener("DOMContentLoaded", () => {
  const getTheBook = document.getElementById("getTheBook")
  const convertkit = document.getElementById("formkit")
  if (convertkit && getTheBook) {
    // wait for the user to click the `Get the book` link to download the convertkit js
    getTheBook.addEventListener('click', (e) => {
      e.preventDefault()
      const script = document.createElement("script")
      script.src = convertkit.dataset.url
      script.dataset.uid = convertkit.dataset.uid
      script.async = "true"
      convertkit.insertAdjacentElement("afterbegin", script)

      setTimeout(() => {
        getTheBook.click()
      }, 1000)
    }, {once: true})
  }
})